import React, { useEffect, useState } from "react";
import "./CSS/ShopCategory.css";
import Item from "../Components/Item/Item";

const ShopCategory = (props) => {
  const [allproducts, setAllProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [sortOrder, setSortOrder] = useState(null); // For sorting

  useEffect(() => {
    const fetchInfo = () => { 
      fetch('https://api.global-grow-orders.hr/allproducts') 
        .then((res) => res.json()) 
        .then((data) => {
          setAllProducts(data);
          setFilteredProducts(data.filter(item => item.category === props.category));
        });
    };
    
    fetchInfo();
  }, [props.category]);

  // Function to handle sorting
  const sortProducts = (order) => {
    const sortedProducts = [...filteredProducts].sort((a, b) => {
      if (order === 'asc') {
        return a.new_price - b.new_price;
      } else if (order === 'desc') {
        return b.new_price - a.new_price;
      }
      return 0;
    });
    setFilteredProducts(sortedProducts);
  };

  // Helper function to format price in German format
  const formatPrice = (price) => {
    return price.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  return (
    <div className="shopcategory">
      <img src={props.banner} className="shopcategory-banner" alt="" />
      <div className="shopcategory-indexSort">
        <p>Prikazuje se <span>{filteredProducts.length}</span> od {filteredProducts.length} proizvoda</p>
        <div className="shopcategory-sort">
          Poredaj po 
          <select
            onChange={(e) => {
              setSortOrder(e.target.value);
              sortProducts(e.target.value);
            }}
            value={sortOrder || ''}
          >
            <option value="asc">Cijena rastuća</option>
            <option value="desc">Cijena padajuća</option>
          </select>
        </div>
      </div>
      <div className="shopcategory-products">
        {filteredProducts.map((item, i) => (
          <Item
            id={item.id}
            key={i}
            name={item.name}
            image={item.image}
            new_price={formatPrice(item.new_price)}  // Format new price
            old_price={formatPrice(item.old_price)}  // Format old price
          />
        ))}
      </div>
    </div>
  );
};

export default ShopCategory;

import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import footer_logo from "../Assets/logo_big.png";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-copyright">
        <div className="">
          <div className="contact-page--company">
            <div className="contact-page__wrapper con-left">
              <div className="footer-logo">
                <img src={footer_logo} alt="" />
                <p>
                  <strong>Global Grow Orders j.d.o.o.</strong>
                </p>
              </div>
              <div className="row">
                <div className="contact-page--company__registration col-xs-12 col-md-6">
                  <p className="m-0">
                  Trgovina na malo preko pošte ili interneta
                  <br />
                    OIB: <strong>87676809121</strong>
                    <br />
                    Matični broj: <strong>081580432</strong>
                    <br />
                    <strong>Trgovački sud u Zagrebu broj Tt-24/17950-2</strong>
                    <p>
                      Ur. broj rješenja: <strong>555-10-06-24-2</strong>
                    </p>
                  </p>
                  <p>
                    
                    Datum rješenja: <strong>29.04.2024.</strong>
                  </p>
                </div>
                <div className="contact-page--company__bank col-xs-12 col-md-6">
                  <p className="m-0">
                    Adresa tvrtke:{" "}
                    <strong>Sjeverna ulica 23/1, 10000, Zagreb</strong>
                    <br />
                    Adresa skladišta:{" "}
                    <strong>Majstorska ulica 9, 10000, Zagreb</strong>
                    <br />
                    IBAN u <strong>ERSTE & STEIERMÄRKISCHE BANK d.d.</strong> :
                    HR6124020061101223634 (BIC banke: ESBCHR22)
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Link to="/pravila-privatnosti" style={{ textDecoration: "none" }}>
          Pravila Privatnosti
        </Link>
        <Link to="/dostava-i-isporuka" style={{ textDecoration: "none" }}>
          Dostava i Isporuka
        </Link>
        <hr />
        <p>
          Email:{" "}
          <a href="mailto:webshop@global-grow-orders.hr">
            webshop@global-grow-orders.hr
          </a>
        </p>
        <p>Broj: +385 91 8152 868</p>
      </div>
    </div>
  );
};

export default Footer;

import React, { useContext, useRef, useState } from "react";
import "./Navbar.css";
import { Link } from "react-router-dom";
import logo from "../Assets/logo.png";
import cart_icon from "../Assets/cart_icon.png";
import { ShopContext } from "../../Context/ShopContext";
import nav_dropdown from "../Assets/nav_dropdown.png";

const Navbar = () => {
  let [menu, setMenu] = useState("shop");
  const { getTotalCartItems } = useContext(ShopContext);
  const menuRef = useRef();

  const dropdown_toggle = (e) => {
    e.stopPropagation(); // Prevent unwanted event bubbling
    menuRef.current.classList.toggle("nav-menu-visible"); // Toggle the visibility
    e.currentTarget.classList.toggle("open"); // Toggle the dropdown icon rotation
  };

  return (
    <>
      <div className="emailnum">
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-mail"
          >
            <path d="M4 4h16v16H4z"></path>
            <polyline points="22,6 12,13 2,6"></polyline>
          </svg>
          <a href="mailto:webshop@global-grow-orders.hr">
            webshop@global-grow-orders.hr
          </a>
        </div>
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-phone"
          >
            <path d="M22 16.92V21a2 2 0 0 1-2.18 2A19.86 19.86 0 0 1 3 5.18 2 2 0 0 1 5 3h4.09a2 2 0 0 1 2 1.72 12.19 12.19 0 0 0 .57 2.83 2 2 0 0 1-.45 2L9.91 10a16 16 0 0 0 6.29 6.29l1.47-1.47a2 2 0 0 1 2-.45 12.19 12.19 0 0 0 2.83.57 2 2 0 0 1 1.72 2z"></path>
          </svg>
          <div className="">+385 91 8152 868</div>
          
        </div>
      </div>
      <div className="nav">
        <Link
          to="/"
          onClick={() => {
            setMenu("shop");
          }}
          style={{ textDecoration: "none" }}
          className="nav-logo"
        >
          <img src={logo} alt="logo" />
          <p>GGO</p>
        </Link>
        <img
          onClick={dropdown_toggle}
          className="nav-dropdown"
          src={nav_dropdown}
          alt=""
        />
        <ul ref={menuRef} className="nav-menu">
          
          <li
            onClick={() => {
              setMenu("strojevi");
            }}
          >
            <Link to="/strojevi" style={{ textDecoration: "none" }}>
              Strojevi
            </Link>
            {menu === "strojevi" ? <hr /> : <></>}
          </li>
          <li
            onClick={() => {
              setMenu("laseri");
            }}
          >
            <Link to="/laseri" style={{ textDecoration: "none" }}>
              Laseri
            </Link>
            {menu === "laseri" ? <hr /> : <></>}
          </li>
          <li
            onClick={() => {
              setMenu("3d-printeri");
            }}
          >
            <Link to="/3d-printeri" style={{ textDecoration: "none" }}>
              3D Printeri
            </Link>
            {menu === "3d-printeri" ? <hr /> : <></>}
          </li>
          <li
            onClick={() => {
              setMenu("kontakt");
            }}
          >
            
            <Link to="/kontakt" style={{ textDecoration: "none" }}>
              Kontakt
            </Link>
            {menu === "kontakt" ? <hr /> : <></>}
          </li>
        </ul>
        <div className="nav-login-cart">
          {localStorage.getItem("auth-token") ? (
            <button
              onClick={() => {
                localStorage.removeItem("auth-token");
                window.location.replace("/");
              }}
            >
              Odjava
            </button>
          ) : (
            <Link
              to="/prijava"
              onClick={() => setMenu(null)}
              style={{ textDecoration: "none" }}
            >
              <button>Prijava</button>
            </Link>
          )}
          <Link to="/cart" onClick={() => setMenu(null)}>
            <img src={cart_icon} alt="cart" />
          </Link>
          <div className="nav-cart-count">{getTotalCartItems()}</div>
        </div>
      </div>
    </>
  );
};

export default Navbar;

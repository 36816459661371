import React, { useContext, useState, useEffect } from "react";
import "./CartItems.css";
import cross_icon from "../Assets/cart_cross_icon.png";
import { ShopContext } from "../../Context/ShopContext";
import { backend_url } from "../../App";

const CartItems = () => {
  const { products, user } = useContext(ShopContext); // Assuming user is provided in ShopContext
  const { cartItems, removeFromCart, getTotalCartAmount } =
    useContext(ShopContext);

  const [deliveryMethod, setDeliveryMethod] = useState("osobno");
  const [address, setAddress] = useState(""); // State for the delivery address
  const [email, setEmail] = useState(""); // State for the email if user is not logged in

  const DELIVERY_COST_TEXT = "0,00€"; // Delivery cost is by agreement
  const isDeliverySelected = deliveryMethod === "dostava";
  const isUserLoggedIn = user && user.email; // Check if user is logged in

  // Format prices to European style: €20.040,00
  const formatPrice = (price) => {
    return new Intl.NumberFormat("de-DE", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 2,
    }).format(price);
  };

  const applyDiscount = (price) => {
    return price / 1.25; // Reduce price by 25%
  };

  const VAT_RATE = 25;

  // Calculate the amount before VAT (without VAT)
  const calculateAmountWithoutVAT = (totalAmount) => {
    return totalAmount / (1 + VAT_RATE / 100);
  };

  // Calculate the VAT amount
  const calculateVAT = (totalAmount) => {
    return totalAmount - calculateAmountWithoutVAT(totalAmount);
  };

  const totalCartAmount = getTotalCartAmount();
  const totalWithDelivery = totalCartAmount; // Delivery cost is by agreement, not added to total

  const createPaymentJson = () => {
    // If "Dostava na Adresu" is selected and no address is entered, alert the user
    if (isDeliverySelected && address.trim() === "") {
      alert("Molimo unesite adresu za dostavu.");
      return; // Exit the function if no address is provided
    }

    // If user is not logged in and no email is provided, alert the user
    if (!isUserLoggedIn && email.trim() === "") {
      alert("Molimo unesite vašu email adresu.");
      return; // Exit if email is missing
    }

    // Prepare cart items
    const cartItemsDetails = products
      .filter((product) => cartItems[product.id] > 0)
      .map((product) => ({
        productId: product.id,
        name: product.name,
        price: product.new_price,
        quantity: cartItems[product.id],
        totalPrice: product.new_price * cartItems[product.id],
      }));

    const amount = totalWithDelivery * 100;
    // Prepare the data object for the payment request
    const paymentData = {
      // items: cartItemsDetails,
      totalPrice: amount,
      deliveryMethod: deliveryMethod,
      address: isDeliverySelected ? address : null,
      email: isUserLoggedIn ? user.email : email, // Use logged-in user's email or the provided one
    };

    initializePayment(paymentData);
  };

  let token = "";

  const initializePayment = async (paymentData) => {
    try {
      const response = await fetch(`${backend_url}/initialize-payment`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(paymentData),
      });
      const data = await response.json();

      if (response.ok) {
        token = data.Token;
        window.location.href = data.RedirectUrl;
      } else {
        alert("Payment initialization failed: " + data.message);
      }
    } catch (error) {
      alert("Error initializing payment: " + error.message);
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const paymentStatus = urlParams.get("payment");

    if (paymentStatus === "success") {
      alert("Successful payment!");
    }
  }, []);

  return (
    <div className="cartitems">
      <h1>Pregled Košarice</h1>
      <div className="cartitems-format-main">
        <p></p>
        <p></p>
        <p>Cijena</p>
        <p>Količina</p>
        <p>Iznos</p>
        <p>Ukloni</p>
      </div>
      <hr />
      {products.map((e) => {
        if (cartItems[e.id] > 0) {
          return (
            <div key={e.id}>
              <div className="cartitems-format-main cartitems-format">
                <img
                  className="cartitems-product-icon"
                  src={backend_url + e.image}
                  alt=""
                />
                <p className="cartitems-product-title">{e.name}</p>
                <p>
                  {formatPrice(applyDiscount(e.new_price) * cartItems[e.id])}
                </p>
                <button className="cartitems-quantity">
                  {cartItems[e.id]}
                </button>
                <p>{formatPrice(e.new_price * cartItems[e.id])}</p>
                <p>
                  <img
                    onClick={() => {
                      removeFromCart(e.id);
                    }}
                    className="cartitems-remove-icon"
                    src={cross_icon}
                    alt=""
                  />
                </p>
              </div>
              <hr />
            </div>
          );
        }
        return null;
      })}

      <div className="cartitems-down">
        <div className="cartitems-delivery">
          <h2>Odaberite način dostave</h2>
          <div>
            <div>
              <input
                type="radio"
                id="osobno"
                name="delivery"
                value="osobno"
                checked={deliveryMethod === "osobno"}
                onChange={() => setDeliveryMethod("osobno")}
              />
              <label htmlFor="osobno">Osobno preuzimanje</label>
            </div>
            <div>
              <input
                type="radio"
                id="dostava"
                name="delivery"
                value="dostava"
                checked={deliveryMethod === "dostava"}
                onChange={() => setDeliveryMethod("dostava")}
              />
              <label htmlFor="dostava">Dostava na Adresu</label>
            </div>

            {/* Conditionally render the address input if "Dostava na Adresu" is selected */}
            {isDeliverySelected && (
              <div className="address-input">
                <label htmlFor="address">Unesite vašu adresu:</label>
                <input
                  type="text"
                  id="address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  placeholder="Unesite adresu"
                  required
                />
              </div>
            )}
          </div>
          {!isUserLoggedIn && (
            <div className="address-input">
              <label htmlFor="email">Unesite vašu email adresu:</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Unesite email"
                required
              />
            </div>
          )}
        </div>

        <div className="cartitems-total">
          <h1>Pregled plaćanja</h1>
          <div>
            <div className="cartitems-total-item">
              <p>Cijena</p>
              <p>{formatPrice(calculateAmountWithoutVAT(totalCartAmount))}</p>
            </div>
            <hr />
            <div className="cartitems-total-item">
              <p>PDV (25%)</p>
              <p>{formatPrice(calculateVAT(totalCartAmount))}</p>
            </div>
            <hr />
            <div className="cartitems-total-item">
              <p>Dostava</p>
              <p className="grin">
                {isDeliverySelected ? DELIVERY_COST_TEXT : "0,00€"}
              </p>
            </div>
            <hr />
            <div className="cartitems-total-item">
              <h3>Iznos</h3>
              <h3>{formatPrice(totalWithDelivery)}</h3>
            </div>
          </div>
          {totalCartAmount > 0 && (
            <button onClick={createPaymentJson}>IDI NA PLAĆANJE</button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CartItems;

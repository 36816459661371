import React, { useEffect, useState } from 'react';
import Hero from '../Components/Hero/Hero';
import Popular from '../Components/Popular/Popular';
import NewsLetter from '../Components/NewsLetter/NewsLetter';
import { Link } from "react-router-dom";
import "./CSS/Shop.css";

const Shop = () => {
  const [popular, setPopular] = useState([]);
  const [showCookieConsent, setShowCookieConsent] = useState(false);

  const fetchInfo = () => { 
    fetch('https://api.global-grow-orders.hr/popular') 
            .then((res) => res.json()) 
            .then((data) => setPopular(data));
  };

  // Check if the user has already accepted the cookies
  useEffect(() => {
    const cookiesAccepted = localStorage.getItem('cookiesAccepted');
    if (!cookiesAccepted) {
      setShowCookieConsent(true);
    }
  }, []);

  // Handle accepting cookies
  const handleAcceptCookies = () => {
    localStorage.setItem('cookiesAccepted', 'true');
    setShowCookieConsent(false);
  };

  useEffect(() => {
    fetchInfo();
  }, []);

  return (
    <div>
      <Hero />
      <Popular data={popular} />
      <NewsLetter />

      {showCookieConsent && (
        <div className="cookie-consent">
          <p>
            Ova stranica koristi kolačiće kako bi osigurala najbolje iskustvo. 
            <Link to="/pravila-privatnosti" className="privacy-policy-link" style={{ textDecoration: "none" }}>
            Pravila privatnosti
            </Link>
          </p>
          <button onClick={handleAcceptCookies}>Prihvaćam</button>
        </div>
      )}
    </div>
  );
};

export default Shop;

import Navbar from "./Components/Navbar/Navbar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Shop from "./Pages/Shop";
import Cart from "./Pages/Cart";
import Product from "./Pages/Product";
import Footer from "./Components/Footer/Footer";
import ShopCategory from "./Pages/ShopCategory";
import LoginSignup from "./Pages/LoginSignup";
import PravilaPrivatnosti from "./Pages/PravilaPrivatnosti";
import DostavaIsporuka from "./Pages/DostavaIsporuka";
import Kontakt from "./Pages/Kontakt";
import kid_banner from "./Components/Assets/banner_kids.png";

// export const backend_url = 'http://localhost:4000';
export const backend_url = 'https://api.global-grow-orders.hr';

export const currency = '€';

function App() {

  return (
    <div>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Shop gender="all" />} />
          <Route path="/strojevi" element={<ShopCategory banner={kid_banner} category="strojevi" />} />
          <Route path="/laseri" element={<ShopCategory banner={kid_banner} category="laseri" />} />
          <Route path="/3d-printeri" element={<ShopCategory banner={kid_banner} category="3d-printeri" />} />
          <Route path='/product' element={<Product />}>
            <Route path=':productId' element={<Product />} />
          </Route>
          <Route path="/cart" element={<Cart />} />
          <Route path="/prijava" element={<LoginSignup/>} />
          <Route path="/pravila-privatnosti" element={<PravilaPrivatnosti />} />
          <Route path="/dostava-i-isporuka" element={<DostavaIsporuka />} />
          <Route path="/kontakt" element={<Kontakt />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;

import React, { useEffect } from "react";
import "./CSS/PravilaPrivatnosti.css";

const DostavaIsporuka = () => {
  // Scroll to top when component is rendered
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="translations-content-container">
      <div className="container">
        <div
          className="tab-content translations-content-item en visible"
          id="en"
        >
          <h1>Dostava i isporuka - Global Grow Orders j.d.o.o.</h1>
          <br />
          <br />
          <br />
          <h3>
            {" "}
            <strong>POTVRDA NARUDŽBE</strong>
          </h3>
          <p>
            Kupac će na e-mail adresu, unesenu prilikom narudžbe, primiti
            potvrdu narudžbe.
          </p>
          <br />
          <h3><strong>VRSTE DOSTAVE</strong></h3>
          <p>
            Global Grow Orders j.d.o.o. nudi dvije vrste dostave za sve
            proizvode:
          </p>
          <br />
          <ul>
            <li>
              <p>
                <strong>Osobno preuzimanje:</strong>{" "}
                Kupac može preuzeti naručene proizvode osobno u našem skladištu
                na adresi: <strong>Majstorska ulica 9, Zagreb, Hrvatska</strong>. Molimo da nas
                unaprijed obavijestite o planiranom dolasku kako bismo osigurali
                pravovremenu pripremu vaše narudžbe.
              </p>
            </li>
            <br />
            <li>
              <p>
                <strong>Besplatna dostava na adresu:</strong> Sva naručena roba
                dostavlja se besplatno na adresu koju kupac navede prilikom
                narudžbe. Dostavni rokovi: Dostava unutar Hrvatske (osim otoka)
                traje između 3 i 5 radnih dana. Ako se radi o posebnoj narudžbi,
                kupac će biti obaviješten o mogućem dužem roku isporuke.
              </p>
            </li>
          </ul>
          <br />
          <br />
          <br />
          <h3>POVRAT ROBE / REKLAMACIJA</h3>
          <p>
            Kupac ima pravo na povrat ili reklamaciju robe s greškom u zakonski
            propisanom roku. Reklamacije je potrebno poslati na e-mail:{" "}
            <a href="mailto:webshop@global-grow-orders.hr">
              webshop@global-grow-orders.hr
            </a>
            , nakon čega će kupac dobiti daljnje upute. Ako zamjena robe nije
            moguća, kupcu će biti vraćen cjelokupan iznos uplaćen za proizvod.
          </p>
          <br />
          <br />
          <br />
          <h4>PISANI PRIGOVOR</h4>
          <p>
            Kupac može poslati pisani prigovor na e-mail:{" "}
            <a href="mailto:webshop@global-grow-orders.hr">
              webshop@global-grow-orders.hr
            </a>
            , sukladno članku 10 Zakona o zaštiti potrošača..
          </p>
        </div>
      </div>
    </div>
  );
};

export default DostavaIsporuka;

import React, { useState } from "react";
import "./CSS/LoginSignup.css";

const LoginSignup = () => {

  const [state, setState] = useState("Login");
  const [formData, setFormData] = useState({ username: "", email: "", password: "" });

  const changeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const login = async () => {
    let dataObj;
    await fetch('https://api.global-grow-orders.hr/prijava', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        
      },
      body: JSON.stringify(formData),
    })
      .then((resp) => resp.json())
      .then((data) => { dataObj = data });
    
    if (dataObj.success) {
      localStorage.setItem('auth-token', dataObj.token);
      window.location.replace("/");
    } else {
      alert(dataObj.errors);
    }
  };

  const signup = async () => {
    let dataObj;

    // Step 1: Verify email before signup
    const verifyResponse = await fetch('https://api.global-grow-orders.hr/verify-email', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: formData.email }),
    });

    const verifyData = await verifyResponse.json();

    // If email verification fails, show an error and stop the process
    if (!verifyResponse.ok) {
      alert(verifyData.message || 'Neispravna e-mail adresa.');
      return;
    }

    // Step 2: Proceed with signup if email is valid
    await fetch('https://api.global-grow-orders.hr/signup', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then((resp) => resp.json())
      .then((data) => { dataObj = data });

    if (dataObj.success) {
      localStorage.setItem('auth-token', dataObj.token);
      window.location.replace("/");
    } else {
      alert(dataObj.errors);
    }
  };

  return (
    <div className="loginsignup">
      <div className="loginsignup-container">
        <h1>{state}</h1>
        <div className="loginsignup-fields">
          {state === "Registracija" ? (
            <input
              type="text"
              placeholder="Vaše Ime"
              name="username"
              value={formData.username}
              onChange={changeHandler}
            />
          ) : null}
          <input
            type="email"
            placeholder="Email Adresa"
            name="email"
            value={formData.email}
            onChange={changeHandler}
          />
          <input
            type="password"
            placeholder="Lozinka"
            name="password"
            value={formData.password}
            onChange={changeHandler}
          />
        </div>

        <button onClick={() => { state === "Login" ? login() : signup() }}>
          Nastavi
        </button>

        {state === "Login" ? (
          <p className="loginsignup-login">
            Još nemate račun?{" "}
            <span onClick={() => { setState("Registracija"); }}>
              Registrirajte se
            </span>
          </p>
        ) : (
          <p className="loginsignup-login">
            Već imate račun?{" "}
            <span onClick={() => { setState("Login"); }}>
              Prijavite se
            </span>
          </p>
        )}
      </div>
    </div>
  );
};

export default LoginSignup;

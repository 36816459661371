import React, { useState } from 'react';
import "./CSS/Kontakt.css";

const Kontakt = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Check if the inputs are empty
    if (email.trim() === '' || message.trim() === '') {
      alert('Molimo unesite vašu email adresu i poruku.');
      return;
    }

    // Create the mailto link
    const subject = encodeURIComponent("Upit s kontakt stranice");
    const body = encodeURIComponent(`Od: ${email}\n\nPoruka: ${message}`);
    const mailtoLink = `mailto:webshop@global-grow-orders.hr?subject=${subject}&body=${body}`;

    // Redirect to the mailto link
    window.location.href = mailtoLink;
  };

  return (
    <div className="kontakt-container">
      <h1>Kontaktirajte nas</h1>
      <p><strong>Tvrtka: </strong> Global Grow Orders j.d.o.o.</p>
      <p><strong>Adresa: </strong> Sjeverna ulica 23/1, 10000 Zagreb, Hrvatska</p>
      <p><strong>Telefon: </strong> +385 91 8152 868</p>
      <p>
        <strong>Email: </strong> 
        <a href="mailto:webshop@global-grow-orders.hr" className="kontakt-email">
          webshop@global-grow-orders.hr
        </a>
      </p>

      <form className="kontakt-form" onSubmit={handleSubmit}>
        <label htmlFor="email">Vaša email adresa:</label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Unesite vašu email adresu"
          required
        />

        <label htmlFor="message">Vaša poruka:</label>
        <textarea
          id="message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Unesite vašu poruku"
          rows="5"
          required
        />

        <button type="submit" className="kontakt-submit-btn">Pošaljite poruku</button>
      </form>
    </div>
  );
};

export default Kontakt;

import React from "react";
import "./Popular.css";
import Item from "../Item/Item";
import img1 from "../Assets/badge.png"
import img2 from "../Assets/customer-service1.png"
import img3 from "../Assets/customer-service.png"
import img4 from "../Assets/medal.png"

const Popular = (props) => {
  const formatPrice = (price) => {
    return new Intl.NumberFormat("de-DE", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    }).format(price);
  };

  return (
    <div className="popular">
      <h1>Popularni Proizvodi</h1>
      <hr />
      <div className="popular-item">
        {props.data.map((item, index) => {
          return (
            <Item
              id={item.id}
              key={index}
              name={item.name}
              image={item.image}
              new_price={formatPrice(item.new_price)}
              old_price={formatPrice(item.old_price)}
            />
          );
        })}
      </div>
      <div className="medal">
        <div className="medal-item">
          <img src={img1} alt="" />
          <strong className="">Iskustvo i kvaliteta</strong>
        </div>
        <div className="medal-item">
          <img src={img2} alt="" />
          <strong className="">Savjeti i podrska</strong>
        </div>
        <div className="medal-item">
          <img src={img3} alt="" />
          <strong className="">Vlastiti servis</strong>
        </div>
        <div className="medal-item">
          <img src={img4} alt="" />
          <strong className="">Jamstvo zadovoljstva</strong>
        </div>
      </div>

      <div id="o-nama" className="text-container">
        <h1>O nama</h1>
        <hr />
        <div className="text">
          <span>Global Grow Orders j.d.o.o.</span> je specijalizirana tvrtka
          koja se bavi prodajom visokokvalitetnih industrijskih strojeva,
          uključujući <span>CNC strojeve, laserske gravirke i 3D printere.</span>{" "}
          S bogatim iskustvom u industriji, naš tim stručnjaka predan je
          pružanju inovativnih rješenja koja zadovoljavaju specifične potrebe
          naših klijenata, bez obzira na veličinu ili djelatnost.
        </div>
        <div className="text">
          Naša misija je osigurati <span>najsuvremeniju opremu</span> koja
          omogućava tvrtkama da unaprijede svoje proizvodne procese, povećaju
          preciznost i efikasnost, te se izdignu iznad konkurencije. Kroz
          personalizirani pristup i stručnu podršku, pomažemo vam da odaberete
          strojeve koji najbolje odgovaraju vašim zahtjevima. S našim
          proizvodima, možete biti sigurni da{" "}
          <span>dobivate vrhunsku kvalitetu, </span>od odabira i instalacije do
          obuke i postprodajne podrške.
        </div>
        <div className="text">
          Global Grow Orders j.d.o.o. je vaš pouzdan partner na putu prema
          tehnološkom napretku i poslovnom uspjehu.
        </div>
      </div>
    </div>
  );
};

export default Popular;

import React, { useContext, useState } from "react";
import "./ProductDisplay.css";
import star_icon from "../Assets/star_icon.png";
import star_dull_icon from "../Assets/star_dull_icon.png";
import { ShopContext } from "../../Context/ShopContext";
import { backend_url } from "../../App";

const ProductDisplay = ({ product }) => {
  const { addToCart } = useContext(ShopContext);
  const images = [
    product.image,
    product.image1,
    product.image2,
    product.image3,
    product.image4,
  ].filter(Boolean); // Filter out null, undefined, or empty string values
  const [mainImage, setMainImage] = useState(backend_url + product.image); // Set the main image to the first available image
  const [activeImageIndex, setActiveImageIndex] = useState(0); // Track which image is selected

  // Format prices to European style: €20.040,00
  const formatPrice = (price) => {
    return new Intl.NumberFormat("de-DE", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 2,
    }).format(price);
  };

  // Handle image click, update the main image and set active image index
  const handleImageClick = (imageUrl, index) => {
    setMainImage(imageUrl);
    setActiveImageIndex(index);
  };

  return (
    <div className="productdisplay">
      <div className="productdisplay-left">
        <div className="productdisplay-img-list">
          {images.map((imageUrl, index) => (
            <div className="img" key={index}>
              <img
                className={activeImageIndex === index ? "imageActive" : ""}
                src={backend_url + imageUrl}
                alt={`img-${index}`}
                onClick={() => handleImageClick(backend_url + imageUrl, index)}
              />
            </div>
          ))}
        </div>
        <div className="productdisplay-img">
          <img
            className="productdisplay-main-img"
            src={mainImage} // Set the main image to the selected one
            alt="main-img"
          />
        </div>
      </div>
      <div className="productdisplay-right">
        <div>
          <h1>{product.name}</h1>
          <div className="productdisplay-right-stars">
            <img src={star_icon} alt="" />
            <img src={star_icon} alt="" />
            <img src={star_icon} alt="" />
            <img src={star_icon} alt="" />
            <img src={star_dull_icon} alt="" />
            <p>(122)</p>
          </div>
          <div className="productdisplay-right-prices">
            <div className="productdisplay-right-price-old">
              {formatPrice(product.old_price)}
            </div>
            <div className="productdisplay-right-price-new">
              {formatPrice(product.new_price)}
            </div>
          </div>
        </div>
        <button className="addtocart" onClick={() => addToCart(product.id)}>
          DODAJ U KOŠARICU
        </button>
      </div>
    </div>
  );
};

export default ProductDisplay;

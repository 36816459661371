import React, { useEffect } from "react";
import "./CSS/PravilaPrivatnosti.css";

const PravilaPrivatnosti = () => {
  // Scroll to top when component is rendered
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="translations-content-container">
      <div className="container">
        <div
          className="tab-content translations-content-item en visible"
          id="en"
        >
          <h1>Pravila Privatnosti</h1>
          <p>Zadnje ažuriranje: 7. rujna 2024</p>
          <br />
          <p>
            Ova Politika privatnosti opisuje naše politike i postupke vezane uz
            prikupljanje, korištenje i otkrivanje vaših podataka kada koristite
            Uslugu, te vas informira o vašim pravima na privatnost i kako vas
            zakon štiti.
          </p>
          <br />
          <p>
            Koristimo vaše osobne podatke za pružanje i poboljšanje Usluge.
            Korištenjem Usluge, pristajete na prikupljanje i korištenje podataka
            u skladu s ovom Politikom privatnosti. Ova Politika privatnosti
            izrađena je uz pomoć Generatora politike privatnosti.
          </p>
          <br />
          <br />
          <h2>Tumačenje i definicije</h2>
          <br />
          <h3>Tumačenje</h3>
          <p>
            Riječi kojima je početno slovo veliko imaju značenja definirana pod
            sljedećim uvjetima. Sljedeće definicije imat će isto značenje bez
            obzira pojavljuju li se u jednini ili množini.
          </p>
          <br />
          <h3>Definicije</h3>
          <p>Za potrebe ovih Pravila privatnosti:</p>
          <ul>
            <li>
              <p>
                <strong>Korisnički račun</strong> znači jedinstveni račun
                kreiran za vas kako biste mogli pristupiti našoj usluzi ili
                njezinim dijelovima naše usluge.
              </p>
            </li>
            <br />
            <li>
              <p>
                <strong>Povezana osoba</strong> znači entitet koji kontrolira,
                pod kontrolom je ili je pod zajedničkom kontrolom s nekom
                stranom, gdje "kontrola" znači vlasništvo nad 50% ili više
                dionica, vlasničkog interesa ili drugih vrijednosnih papira koji
                omogućuju pravo glasa pri izboru direktora ili druge upravljačke
                vlasti.
              </p>
            </li>
            <br />
            <li>
              <p>
                <strong>Tvrtka </strong> (u daljnjem tekstu "Tvrtka", "Mi",
                "Nas" ili "Naše" u ovom Ugovoru) odnosi se na Global Grow Orders
                j.d.o.o., Sjeverna ulica 23/1, 10000, Zagreb, Hrvatska.
              </p>
            </li>
            <br />
            <li>
              <p>
                <strong>Kolačići</strong> su male datoteke koje web stranica
                postavlja na vaše računalo, mobilni uređaj ili neki drugi
                uređaj, a sadrže pojedinosti o vašoj povijesti pregledavanja na
                toj web stranici te druge svrhe.
              </p>
            </li>
            <br />
            <li>
              <p>
                <strong>Zemlja</strong> odnosi se na: Hrvatsku
              </p>
            </li>
            <br />
            <li>
              <p>
                <strong>Uređaj</strong> means any device that can access the
                Service such as a computer, a cellphone or a digital tablet.
              </p>
            </li>
            <br />
            <li>
              <p>
                <strong>Osobni podaci</strong> su bilo koje informacije koje se
                odnose na identificiranu ili prepoznatljivu osobu.
              </p>
            </li>
            <li>
              <p>
                <strong>Usluga</strong> odnosi se na Web stranicu.
              </p>
            </li>
            <br />
            <li>
              <p>
                <strong>Pružatelj usluga</strong> znači bilo koja fizička ili
                pravna osoba koja obrađuje podatke u ime Tvrtke. Odnosi se na
                treće strane, poduzeća ili pojedince koje Tvrtka angažira radi
                olakšavanja pružanja Usluge, pružanja Usluge u ime Tvrtke,
                obavljanja usluga povezanih s Uslugom ili radi pomoći Tvrtki u
                analizi načina na koji se Usluga koristi.
              </p>
            </li>
            <br />
            <li>
              <p>
                <strong>Podaci o korištenju</strong> odnose se na podatke
                prikupljene automatski, bilo da ih generira korištenje Usluge
                ili iz same infrastrukture Usluge (primjerice, trajanje posjeta
                stranici).
              </p>
            </li>
            <br />
            <li>
              <p>
                <strong>Web stranica</strong> odnosi se na Global Grow Orders,
                dostupna na{" "}
                <a
                  href="https://global-grow-orders.hr"
                  rel="external nofollow noopener noreferrer"
                  target="_blank"
                >
                  https://global-grow-orders.hr
                </a>
              </p>
            </li>
            <br />
            <li>
              <p>
                <strong>Vi</strong> znači osoba koja pristupa ili koristi
                Uslugu, ili poduzeće, ili drugo pravno tijelo u ime kojeg takva
                osoba pristupa ili koristi Uslugu, prema potrebi.
              </p>
            </li>
          </ul>
          <br />
          <br />
          <h2>Prikupljanje i korištenje vaših osobnih podataka</h2>
          <br />
          <h3>Vrste podataka koje prikupljamo</h3>
          <br />
          <h4>Osobni podaci</h4>
          <p>
            Tijekom korištenja naše Usluge, možemo od vas zatražiti da nam
            pružite određene osobne podatke koji se mogu koristiti za kontakt
            ili identifikaciju. Osobni podaci mogu uključivati, ali nisu
            ograničeni na:
          </p>
          <ul>
            <li>
              <p>Adresa e-pošte</p>
            </li>
            <li>
              <p>Ime i prezime</p>
            </li>
            <li>
              <p>Podaci o korištenju</p>
            </li>
          </ul>
          <br />
          <h4>Podaci o korištenju</h4>
          <p>
            Podaci o korištenju prikupljaju se automatski prilikom korištenja
            Usluge.
          </p>
          <p>
            Podaci o korištenju mogu uključivati informacije poput IP adrese
            vašeg uređaja, vrste preglednika, verzije preglednika, stranica naše
            Usluge koje posjećujete, vrijeme i datum posjeta, vrijeme provedeno
            na tim stranicama, jedinstvene identifikatore uređaja i druge
            dijagnostičke podatke.
          </p>
          <p>
            Kada pristupate Usluzi putem mobilnog uređaja, možemo automatski
            prikupljati određene podatke, uključujući, ali ne ograničavajući se
            na vrstu mobilnog uređaja koji koristite, jedinstveni ID vašeg
            mobilnog uređaja, IP adresu vašeg mobilnog uređaja, operacijski
            sustav vašeg mobilnog uređaja, vrstu mobilnog internetskog
            preglednika koji koristite, jedinstvene identifikatore uređaja i
            druge dijagnostičke podatke.
          </p>
          <p>
            Također možemo prikupljati informacije koje vaš preglednik šalje kad
            god posjetite našu Uslugu ili kada pristupate Usluzi putem mobilnog
            uređaja.
          </p>
          <br />
          <h4>Tehnologije praćenja i Kolačići</h4>
          <p>
            Koristimo Kolačiće i slične tehnologije praćenja kako bismo pratili
            aktivnosti na našoj Usluzi i pohranili određene informacije.
            Tehnologije praćenja koje koristimo uključuju beacone, oznake i
            skripte za prikupljanje i praćenje podataka te za poboljšanje i
            analizu naše Usluge. Tehnologije koje koristimo mogu uključivati:
          </p>
          <ul>
            <li>
              <strong>Kolačići ili preglednički kolačići.</strong> Kolačić je
              mala datoteka koja se postavlja na vaš uređaj. Možete postaviti
              svoj preglednik da odbija sve kolačiće ili da naznači kada je
              kolačić poslan. Međutim, ako ne prihvatite kolačiće, možda nećete
              moći koristiti neke dijelove naše Usluge. Ako niste prilagodili
              postavke preglednika da odbija kolačiće, naša Usluga može
              koristiti kolačiće.
            </li>
            <li>
              <strong>Web Svjetionik.</strong> Određeni dijelovi naše Usluge i
              naše e-poruke mogu sadržavati male elektroničke datoteke poznate
              kao web pratilice (također poznate kao prozirni GIF-ovi, oznake
              piksela i jednopikselni GIF-ovi) koje omogućuju Tvrtki, na
              primjer, brojanje korisnika koji su posjetili te stranice ili
              otvorili e-poštu i za druge povezane statistike (primjerice,
              bilježenje popularnosti određenog odjeljka i provjeru integriteta
              sustava i poslužitelja).
            </li>
          </ul>
          <p>
            Kolačići mogu biti "trajni" ili "sesijski" kolačići. Trajni kolačići
            ostaju na vašem osobnom računalu ili mobilnom uređaju kada idete
            izvan mreže, dok se sesijski kolačići brišu čim zatvorite web
            preglednik. Više o kolačićima možete saznati u članku na{" "}
            <a
              href="https://www.termsfeed.com/blog/cookies/#What_Are_Cookies"
              target="_blank"
              rel="noreferrer"
            >
              web stranici TermsFeed.
            </a>
          </p>
          <p>
            Koristimo i sesijske i trajne kolačiće za potrebe navedene u
            nastavku:
          </p>
          <ul>
            <li>
              <p>
                <strong>Neophodni / Osnovni kolačići</strong>
              </p>
              <p>Vrsta: Sesijski kolačići</p>
              <p>Upravljano od strane: Nas</p>
              <p>
                Svrha: Ovi kolačići su neophodni za pružanje usluga dostupnih
                putem web stranice i omogućuju vam korištenje određenih
                značajki. Oni pomažu u autentifikaciji korisnika i sprječavanju
                prijevare pri korištenju korisničkih računa. Bez ovih kolačića,
                usluge koje ste zatražili ne mogu biti pružene, a mi ih
                koristimo isključivo za pružanje tih usluga.
              </p>
            </li>
            <li>
              <p>
                <strong>
                  Kolačići za prihvaćanje politike kolačića / obavijesti
                </strong>
              </p>
              <p>Vrsta: Trajni kolačići</p>
              <p>Upravljano od strane: Nas</p>
              <p>
                Svrha: Ovi kolačići prepoznaju jesu li korisnici prihvatili
                korištenje kolačića na web stranici.
              </p>
            </li>
            <li>
              <p>
                <strong>Kolačići funkcionalnosti</strong>
              </p>
              <p>Vrsta: Trajni kolačići</p>
              <p>Upravljano od strane: Nas</p>
              <p>
                Svrha: Ovi kolačići omogućuju nam da zapamtimo vaše odabire
                prilikom korištenja web stranice, kao što su podaci o prijavi
                ili preferencije jezika. Svrha ovih kolačića je pružiti vam
                osobnije iskustvo i izbjeći da ponovno unosite svoje
                preferencije svaki put kada koristite web stranicu.
              </p>
            </li>
          </ul>
          <p>
            Za više informacija o kolačićima koje koristimo i vašim odabirima u
            vezi s kolačićima, posjetite našu Politiku kolačića ili odjeljak
            Kolačići u našoj Politici privatnosti.
          </p>
          <br />
          <h3>Korištenje vaših osobnih podataka</h3>
          <p>Tvrtka može koristiti osobne podatke u sljedeće svrhe:</p>
          <ul>
            <li>
              <p>
                <strong>Za pružanje i održavanje naše Usluge</strong>,
                uključujući praćenje korištenja naše Usluge.
              </p>
            </li>
            <br />
            <li>
              <p>
                <strong>Za upravljanje Vašim računom:</strong> za upravljanje
                Vašom registracijom kao korisnika Usluge. Osobni podaci koje
                pružite mogu Vam omogućiti pristup različitim funkcionalnostima
                Usluge koje su Vam dostupne kao registriranom korisniku.
              </p>
            </li>
            <br />
            <li>
              <p>
                <strong>Za izvršenje ugovora:</strong> razvoj, usklađenost i
                provedba ugovora o kupnji proizvoda, stavki ili usluga koje ste
                kupili ili bilo kojeg drugog ugovora s Nama putem Usluge.
              </p>
            </li>
            <br />
            <li>
              <p>
                <strong>Za kontaktiranje Vas:</strong> Kontaktirati Vas putem
                e-pošte, telefonskih poziva, SMS-a ili drugih ekvivalentnih
                oblika elektroničke komunikacije, kao što su push obavijesti
                mobilnih aplikacija vezane uz ažuriranja ili informativne
                komunikacije o funkcionalnostima, proizvodima ili ugovorenim
                uslugama, uključujući sigurnosna ažuriranja, kada je to potrebno
                ili razumno za njihovu provedbu.
              </p>
            </li>
            <br />
            <li>
              <p>
                <strong>Za pružanje informacija:</strong> o novostima, posebnim
                ponudama i općim informacijama o drugim proizvodima, uslugama i
                događajima koje nudimo, a koji su slični onima koje ste već
                kupili ili o kojima ste se raspitivali, osim ako ste odabrali da
                ne primate takve informacije.
              </p>
            </li>
            <br />
            <li>
              <p>
                <strong>Za upravljanje Vašim zahtjevima:</strong> kako bismo
                odgovorili i upravljali Vašim zahtjevima upućenima Nama.
              </p>
            </li>
            <br />
            <li>
              <p>
                <strong>Za poslovne transfere:</strong> Možemo koristiti Vaše
                podatke za procjenu ili provedbu spajanja, rasprodaje,
                restrukturiranja, reorganizacije, raspuštanja ili drugog
                prijenosa dijela ili svih naših imovina, bilo kao dio aktivnog
                poslovanja ili u okviru stečaja, likvidacije ili sličnog
                postupka, pri čemu su osobni podaci koje posjedujemo o
                korisnicima naše Usluge među prenesenom imovinom.
              </p>
            </li>
            <br />
            <li>
              <p>
                <strong>Za druge svrhe:</strong> Možemo koristiti Vaše podatke
                za druge svrhe, poput analize podataka, identifikacije trendova
                korištenja, određivanja učinkovitosti naših promotivnih kampanja
                te evaluacije i poboljšanja naše Usluge, proizvoda, usluga,
                marketinga i Vašeg iskustva.
              </p>
            </li>
            <br />
          </ul>
          <p>Možemo podijeliti Vaše osobne podatke u sljedećim situacijama:</p>
          <br />
          <ul>
            <li>
              <strong>S Pružateljima usluga:</strong> Možemo podijeliti Vaše
              osobne podatke s pružateljima usluga kako bismo pratili i
              analizirali korištenje naše Usluge te kako bismo Vas kontaktirali.
            </li>
            <br />
            <li>
              <strong>Za poslovne transfere:</strong> Možemo podijeliti ili
              prenijeti Vaše osobne podatke u vezi s ili tijekom pregovora o
              bilo kojem spajanju, prodaji imovine Tvrtke, financiranju ili
              akviziciji cijelog ili dijela našeg poslovanja od strane druge
              tvrtke.
            </li>
            <br />
            <li>
              <strong>S Povezanim osobama:</strong> Možemo podijeliti Vaše
              podatke s našim povezanim osobama, u kojem slučaju ćemo
              zahtijevati od tih povezanih osoba da poštuju ovu Politiku
              privatnosti. Povezane osobe uključuju našu matičnu tvrtku i bilo
              koje druge podružnice, partnere u zajedničkim pothvatima ili druge
              tvrtke koje kontroliramo ili koje su pod zajedničkom kontrolom s
              Nama.
            </li>
            <br />
            <li>
              <strong>S poslovnim partnerima:</strong> Možemo podijeliti Vaše
              podatke s našim poslovnim partnerima kako bismo Vam ponudili
              određene proizvode, usluge ili promocije.
            </li>
            <br />
            <li>
              <strong>S drugim korisnicima:</strong> Kada dijelite osobne
              podatke ili na drugi način komunicirate u javnim prostorima s
              drugim korisnicima, ti podaci mogu biti vidljivi svim korisnicima
              i mogu biti javno distribuirani izvan Usluge.
            </li>
            <br />
            <li>
              <strong>Uz Vaš pristanak:</strong> Možemo otkriti Vaše osobne
              podatke za bilo koju drugu svrhu uz Vaš pristanak.
            </li>
          </ul>
          <br />
          <h3>Zadržavanje Vaših osobnih podataka</h3>
          <p>
            Tvrtka će zadržati Vaše osobne podatke samo onoliko dugo koliko je
            potrebno za svrhe navedene u ovoj Politici privatnosti. Zadržavat
            ćemo i koristiti Vaše osobne podatke onoliko dugo koliko je potrebno
            za ispunjavanje naših zakonskih obveza (na primjer, ako smo obvezni
            zadržati Vaše podatke radi usklađivanja s važećim zakonima),
            rješavanje sporova i provođenje naših pravnih ugovora i politika.
          </p>
          <p>
            Tvrtka će također zadržati podatke o korištenju za interne
            analitičke svrhe. Podaci o korištenju obično se zadržavaju kraće
            vremensko razdoblje, osim ako se ti podaci koriste za jačanje
            sigurnosti ili za poboljšanje funkcionalnosti naše Usluge, ili smo
            zakonski obvezni zadržati te podatke duže vremensko razdoblje.
          </p>
          <br />
          <h3>Prijenos Vaših osobnih podataka</h3>
          <p>
            Vaši podaci, uključujući osobne podatke, obrađuju se u uredima
            Tvrtke i na bilo kojem drugom mjestu gdje se nalaze strane uključene
            u obradu. To znači da se ti podaci mogu prenijeti i pohraniti na
            računalima koja se nalaze izvan Vaše države, pokrajine, zemlje ili
            druge jurisdikcije, gdje zakoni o zaštiti podataka mogu biti
            drugačiji od zakona u Vašoj jurisdikciji.
          </p>
          <p>
            Vaš pristanak na ovu Politiku privatnosti, nakon čega slijedi Vaša
            predaja takvih podataka, predstavlja Vaš pristanak na taj prijenos.
          </p>
          <p>
            Tvrtka će poduzeti sve razumne korake kako bi osigurala da se Vaši
            podaci tretiraju sigurno i u skladu s ovom Politikom privatnosti te
            da se prijenos Vaših osobnih podataka neće izvršiti na organizaciju
            ili u zemlju ako ne postoje odgovarajuće kontrole, uključujući
            sigurnost Vaših podataka i drugih osobnih informacija.
          </p>
          <br />
          <h3>Brisanje Vaših osobnih podataka</h3>
          <p>
            Imate pravo izbrisati ili zatražiti da Vam pomognemo u brisanju
            osobnih podataka koje smo prikupili o Vama.
          </p>
          <p>
            Naša Usluga može Vam omogućiti brisanje određenih podataka o Vama
            unutar same Usluge.
          </p>
          <p>
            Možete ažurirati, izmijeniti ili izbrisati svoje podatke u bilo
            kojem trenutku prijavom na svoj račun, ako ga imate, i posjetom
            odjeljku postavki računa koji Vam omogućuje upravljanje Vašim
            osobnim podacima. Također nas možete kontaktirati kako biste
            zatražili pristup, ispravak ili brisanje bilo kojih osobnih podataka
            koje ste Nam pružili.
          </p>
          <p>
            Međutim, imajte na umu da ćemo možda morati zadržati određene
            informacije kada imamo zakonsku obvezu ili pravnu osnovu za to.
          </p>
          <br />
          <h3>Otkrivanje Vaših osobnih podataka</h3>
          <br />
          <h4>Poslovne transakcije</h4>
          <p>
            Ako je Tvrtka uključena u spajanje, akviziciju ili prodaju imovine,
            Vaši osobni podaci mogu biti preneseni. Obavijestit ćemo Vas prije
            nego što Vaši osobni podaci budu preneseni i postanu predmetom druge
            Politike privatnosti.
          </p>
          <br />
          <h4>Pravna obveza</h4>
          <p>
            U određenim okolnostima, Tvrtka može biti obvezna otkriti Vaše
            osobne podatke ako to zahtijeva zakon ili kao odgovor na valjane
            zahtjeve javnih tijela (npr. sud ili državna agencija).
          </p>
          <br />
          <h4>Ostali zakonski zahtjevi</h4>
          <p>
            Tvrtka može otkriti Vaše osobne podatke u dobroj vjeri da je takva
            radnja potrebna kako bi se:
          </p>
          <ul>
            <li>Ispunila zakonska obveza</li>
            <li>Zaštitila i obranila prava ili imovinu Tvrtke</li>
            <li>
              Spriječilo ili istražilo moguće nedolično ponašanje u vezi s
              Uslugom
            </li>
            <li>Zaštitila osobna sigurnost korisnika Usluge ili javnosti</li>
            <li>Zaštitilo od pravne odgovornosti</li>
          </ul>
          <br />
          <h3>Sigurnost Vaših osobnih podataka</h3>
          <p>
            Sigurnost Vaših osobnih podataka je važna za nas, ali imajte na umu
            da nijedna metoda prijenosa putem Interneta, niti metoda
            elektroničke pohrane nije 100% sigurna. Iako nastojimo koristiti
            komercijalno prihvatljive metode zaštite Vaših osobnih podataka, ne
            možemo jamčiti njihovu apsolutnu sigurnost.
          </p>
          <br />
          <h2>Privatnost djece</h2>
          <p>
            Naša Usluga nije namijenjena nikome mlađem od 13 godina. Svjesno ne
            prikupljamo osobne podatke od bilo koga mlađeg od 13 godina. Ako ste
            roditelj ili skrbnik i svjesni ste da nam je Vaše dijete pružilo
            osobne podatke, molimo Vas da nas kontaktirate. Ako postanemo
            svjesni da smo prikupili osobne podatke od bilo koga mlađeg od 13
            godina bez provjere roditeljskog pristanka, poduzet ćemo korake da
            te podatke uklonimo s naših poslužitelja.
          </p>
          <p>
            Ako moramo osloniti se na pristanak kao pravnu osnovu za obradu
            Vaših podataka, a Vaša zemlja zahtijeva pristanak roditelja, možda
            ćemo trebati pristanak Vašeg roditelja prije nego što prikupimo i
            koristimo te podatke.
          </p>
          <br />
          <h2>Poveznice na druge web stranice</h2>
          <p>
            Naša Usluga može sadržavati poveznice na druge web stranice koje
            nisu u našem vlasništvu niti pod našom kontrolom. Ako kliknete na
            poveznicu treće strane, bit ćete preusmjereni na web stranicu te
            treće strane. Snažno Vam savjetujemo da pregledate Politiku
            privatnosti svake web stranice koju posjetite.
          </p>
          <p>
            Nemamo kontrolu nad i ne preuzimamo odgovornost za sadržaj, politike
            privatnosti ili postupke bilo koje treće strane web stranica ili
            usluga.
          </p>
          <br />
          <h2>Promjene ove Politike privatnosti</h2>
          <p>
            S vremena na vrijeme možemo ažurirati našu Politiku privatnosti.
            Obavijestit ćemo Vas o bilo kakvim promjenama objavljivanjem nove
            Politike privatnosti na ovoj stranici.
          </p>
          <p>
            Obavijestit ćemo Vas putem e-pošte i/ili istaknute obavijesti na
            našoj Usluzi, prije nego što promjena stupi na snagu, te ćemo
            ažurirati datum "Zadnje ažuriranje" na vrhu ove Politike
            privatnosti.
          </p>
          <p>
            Savjetujemo Vam da povremeno pregledavate ovu Politiku privatnosti
            radi eventualnih promjena. Promjene ove Politike privatnosti stupaju
            na snagu kada budu objavljene na ovoj stranici.
          </p>
          <br />
          <h2>Kontaktirajte nas</h2>
          <p>
            Ako imate bilo kakvih pitanja o ovoj Politici privatnosti, možete
            nas kontaktirati:
          </p>

          <ul>
            <li>
              <p>
                Putem e-maila:{" "}
                <a
                  href="mailto:global.gr.orders@gmail.com"
                  rel="external nofollow noopener noreferrer"
                  target="_blank"
                >
                  global.gr.orders@gmail.com
                </a>
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default PravilaPrivatnosti;

import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from React Router
import './Breadcrums.css';
import arrow_icon from '../Assets/breadcrum_arrow.png';

const Breadcrums = (props) => {
  const { product } = props;
  return (
    <div className='breadcrums'>
      <Link to='/'>Naslovna</Link> 
      <img src={arrow_icon} alt="" /> 
      <Link to={`/${product.category}`}>{product.category}</Link> 
      <img src={arrow_icon} alt="" /> 
      <Link to={`/product/${product.id}`}>{product.name}</Link> 
    </div>
  );
}

export default Breadcrums;

import React from 'react'
import './Item.css'
import { Link } from 'react-router-dom'
import { backend_url, currency } from '../../App'

const Item = (props) => {
  // Limit the name to a maximum of 30 characters
  const truncatedName = props.name.length > 30 ? props.name.slice(0, 24) + '...' : props.name;

  return (
    <div className='item'>
      <Link to={`/product/${props.id}`}><img onClick={() => window.scrollTo(0, 0)} src={backend_url + props.image} alt="products" /></Link>
      <p>{truncatedName}</p>
      <div className="item-prices">
        <div className="item-price-new">{currency}{props.new_price}</div>
        <div className="item-price-old">{currency}{props.old_price}</div>
      </div>
    </div>
  )
}

export default Item

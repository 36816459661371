import React, { useState } from 'react';
import './NewsLetter.css';
import { backend_url } from "../../App";

const NewsLetter = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false); // State to track loading

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loader

    try {
      // Step 1: Verify email
      const verifyResponse = await fetch(`${backend_url}/verify-email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const verifyData = await verifyResponse.json(); // Extract verification response data

      if (!verifyResponse.ok) {
        alert(verifyData.message || 'Neispravna e-mail adresa!');
        setLoading(false);
        return; // Stop further execution if email is invalid
      }

      // Step 2: If email is valid, proceed to subscribe the user
      const response = await fetch(`${backend_url}/newsletter`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json(); // Extract response data

      if (response.ok) {
        alert('Uspješna pretplata na newsletter!');
        setEmail(""); // Clear email field
      } else {
        alert(data.message || 'Neuspješna pretplata na newsletter!');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Došlo je do pogreške. Pokušajte ponovno kasnije.');
    } finally {
      setLoading(false); // Stop loader once request is complete
    }
  };

  return (
    <div className='newsletter'>
      <h1>Želite bolju ponudu?</h1>
      <p>Pretplatite se na naš newsletter i ostanite u toku.</p>
      <div>
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            placeholder="Vaša e-mail adresa"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            disabled={loading} // Disable input while loading
          />
          <button type="submit" disabled={loading}>
            {loading ? 'Slanje...' : 'Pošalji'} {/* Show loading text */}
          </button>
        </form>
      </div>
    </div>
  );
};

export default NewsLetter;
